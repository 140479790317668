import http from "../utils/http"

/**
 * รายการประเภทคูปอง
 * @param {object} params
 */
export function couponTypeList(params) {
    return http({
        url: "/coupon/api/coupon/typepagelists",
        data: params
    })
}

/**
 * รับคูปอง
 * @param {object} params
 */
export function couponReceive(params) {
    return http({
        url: "/coupon/api/coupon/receive",
        data: params,
        forceLogin: true
    })
}

/**
 * รายการคูปองที่ศูนย์บัตรกํานัล
 */
export function couponList(params) {
    let url = "/coupon/api/coupon/typepagelists"
    return http({
        url: url,
        data: params
    })
}

/**
 * รับคูปองที่ร้าน
 */
export function receiveCoupon(params) {
    let url = "/coupon/api/coupon/receive"
    return http({
        url: url,
        data: params,
        forceLogin: true
    })
}

export function goodsCoupon(params) {
    return http({
        url: "/coupon/api/coupon/goodsCoupon",
        data: params
    })
}
